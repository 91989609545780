.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SwvrMnvr {
  display: none;
  margin-top: 20px;
  justify-content: right;
  align-items: center;
}

.SwvrMnvr ul li {
  display: inline-block;
  position: relative;
}

.SwvrMnvr ul li.prev a img {
  width: 6px;
}

.SwvrMnvr ul {
  display: flex;
  align-items: center;
}

.SwvrMnvr ul li.prev {
  margin-top: 0px;
  line-height: 0;
}

.SwvrMnvr ul li.next a img {
  width: 6px;
}

.SwvrMnvr ul li.next {
  line-height: 0;
}

.SwvrMnvr ul li a {
  display: inline-block;
  position: relative;
}

.SwvrMnvr ul li a {
  display: inline-block;
  position: relative;
  padding: 5px 8px;
  font-size: 14px;
}

.cl12Learnmre {
  position: relative;
  display: block;
  position: relative;
  padding: 50px 0px;
}

@media (max-width: 767px) {
  .cl12Learnmre {
    padding: 35px 0px;
  }
}

.cl12Learnmre .cl12SwbrptmNBackimg {
  position: relative;
  /* background-image: url("../images/subscription.png"); */
  display: block;
  background-color: blue;
  padding: 100px 40px 75px 40px;
  border-radius: 15px;
}

@media (max-width: 991px) {
  .cl12Learnmre .cl12SwbrptmNBackimg {
    padding: 50px 10px 50px 10px;
  }
}

.cl12Learnmre .cl12SwbrptmNBackimg::before {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  border-radius: 15px;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.col12LatestUpdtemvrSwn .cl12Swbvrmn .cl4rptmvrSwn {
  width: 23%;
  display: inline-block;
  text-align: center;
  position: relative;
}

@media (max-width: 767px) {
  .col12LatestUpdtemvrSwn .cl12Swbvrmn .cl4rptmvrSwn {
    width: 24%;
  }
}

.col12LatestUpdtemvrSwn .cl12Swbvrmn .cl4rptmvrSwn .TbinSwn.actveblog {
  background: #000;
  color: #fff;
}

.col12LatestUpdtemvrSwn .cl12Swbvrmn .cl4rptmvrSwn .TbinSwn.actveblog::before {
  opacity: 1;
  visibility: visible;
}

.col12LatestUpdtemvrSwn .cl12Swbvrmn .cl4rptmvrSwn .TbinSwn {
  width: 100%;
  color: #fff;
  background: linear-gradient(
    92.15deg,
    #61005d 38.62%,
    rgba(135, 10, 155, 0.54) 108.52%
  );
  padding: 5px 5px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-box-shadow: -4px 4px 11px -1px #909090;
  box-shadow: -4px 4px 11px -1px #909090;
  border-radius: 5px;
  position: relative;
}

.col12LatestUpdtemvrSwn .cl12Swbvrmn .cl4rptmvrSwn .TbinSwn::before {
  border: solid #000;
  border-width: 0 1px 1px 0;
  display: inline-block;
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(-45deg) translate(-50%, 0px);
  transform: rotate(-45deg) translate(-50%, 0px);
  position: absolute;
  content: "";
  background-color: #000;
  bottom: 0px;
  opacity: 0;
  visibility: hidden;
  left: 50%;
}

@media (max-width: 767px) {
  .col12LatestUpdtemvrSwn .cl12Swbvrmn .cl4rptmvrSwn .TbinSwn {
    font-size: 12px;
  }
}

.cl12RprSwnvrclMv .cl4rptmvrSwn {
  width: 30%;
  position: relative;
  text-align: center;
  padding: 40px 30px;
  background: linear-gradient(
    327.13deg,
    rgba(97, 0, 93, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-filter: drop-shadow(-4px 4px 11px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(-4px 4px 11px rgba(0, 0, 0, 0.25));
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 0px 70px;
  -webkit-box-shadow: -4px 4px 11px 0px #00000040;
  box-shadow: -4px 4px 11px 0px #00000040;
}

@media (max-width: 991px) {
  .cl12RprSwnvrclMv .cl4rptmvrSwn {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cl12RprSwnvrclMv .cl4rptmvrSwn small {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.cl12RprSwnvrclMv .cl4rptmvrSwn h5 {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 15px;
}

.cl12RprSwnvrclMv .cl4rptmvrSwn p {
  font-size: 14px;
  color: #fff;
  line-height: 1.4;
}

.cl12RprSwnvrclMv .cl4rptmvrSwn .btnsgnup {
  margin-top: 30px;
  display: block;
  text-align: center;
}

.cl12RprSwnvrclMv .cl4rptmvrSwn .btnsgnup a {
  display: inline-block;
  background: linear-gradient(
    123.85deg,
    #371069 15.39%,
    #b408b7 70.61%,
    #e1078a 109.21%
  );
  padding: 5px 35px;
  color: #fff;
  font-weight: 500;
  position: relative;
  bottom: 0px;
  left: 0px;
  -webkit-transform: none;
  transform: none;
}
