@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;1,100&display=swap");

/* Tinku */

body {
  font-family: "Montserrat", sans-serif !important;
  padding: 0;
  margin: 0;
  /* background-color: #f5f5f5; */
  background-color: white;
}

.font-sans {
  font-family: "Montserrat", sans-serif !important;
}
.LogPswmvrMn {
  position: absolute;
  top: 15px;
}
.baskervville {
  font-family: "Baskervville", serif;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.col12SwmDashBord {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  /* background-color: #ffffff; */
}

/*wejhwqjhe kwjejwqkeqw */
.col12SwmDashBord .col4SemNvr {
  width: auto;
  position: relative;
  height: 100vh;
  position: fixed;
  left: 0px;
  padding-top: 70px;
  z-index: 1;
  background: #61005D;
}
.col12SwmDashBord .col4SemNvr .IcxonSwn.ActivrSwnvr {
  border-left: 2px solid hsl(46, 48%, 60%);
}
.col12SwmDashBord .col4SemNvr .IcxonSwn.ActivrSwnvr .WhteLnSwn {
  background-color: #fff;
}

.col12SwmDashBord .col4SemNvr .IcxonSwn.ActivrSwnvr .WhteLnSwn img {
  filter: invert(1);
}
.col12SwmDashBord .col4SemNvr .IcxonSwn {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: #61005D;    
  padding: 10px;
  cursor: pointer;
  box-shadow: -3px 5px 1px -5px #eee;
  transition: all 1s ease;
}
.col12SwmDashBord .col4SemNvr .IcxonSwn .WhteLnSwn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col12SwmDashBord .col4SemNvr .IcxonSwn:hover {
  border-left: 2px solid #cab367;
}
.col12SwmDashBord .col4SemNvr .IcxonSwn img {
  width: 17px;
}
.col12SwmDashBord .col4SemNvr .LeftSidebar {
  width: 100%;
  position: relative;
  height: 100%;
}
.col12SwmDashBord .col4SemNvr .LeftSidebar::before {
  top: 0px;
  left: 0px;
  height: 100%;
  content: "";
  position: absolute;
  width: 60px;
  height: 100%;
  background-color: #61005D;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.col12SwmDashBord .col4SemNvr .LeftSidebar .col12Swmvr {
  width: 100%;
  position: relative;
  padding: 0px 0px;
  margin-top: 0px;
}
.col12SwmDashBord .col4SemNvr .LeftSidebar .col12Swmvr .ParSwnSwnMnvr {
  display: block;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12.ActmnvrMn {
  display: block;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12 {
  position: absolute;
  top: 0px;
  left: 60px;
  width: 275px;
  padding: 18px 0px;
  display: none;
  z-index: 1;
  height: 91.3vh;
  background-color: #790d75;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 80px;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12
  h4 {
  font-size: 18px;
  color: #ffffff;
  padding: 5px 15px;
  border-bottom: 1px solid #828282;
  padding-bottom: 15px;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12
  ul {
  padding: 0px 18px;
  max-height: 100%;
  overflow: auto;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12
  ul
  li {
  display: block;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12
  ul
  li
  ul.DroDwn.Show {
  display: block;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12
  ul
  li
  ul.DroDwn {
  padding-left: 27px;
  display: none;
  position: relative;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12
  ul
  li
  a {
  display: block;
  padding: 8px 0px;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  position: relative;
}
.col12SwmDashBord
  .col4SemNvr
  .LeftSidebar
  .col12Swmvr
  .ParSwnSwnMnvr
  .SwmvrSwmCl12
  ul
  li
  a
  img {
  width: 16px;
  margin-right: 5px;
  vertical-align: middle;
}

.arrow {
  border: solid #fff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  top: 11px;
  position: absolute;
  right: 0px;
  transition: all 1s ease;
}

span.arrow.DwnArw {
  transform: rotate(225deg);
  top: 20px;
}

/*Coleowqeoj*/

.col8SemNvr {
  width: 95%;
  position: relative;
  padding: 15px;
 
  background-color: white;
}
.col8SemNvr .cl12SwmDashBrd {
  position: relative;
  display: block;
  width: 85%;
}
.col8SemNvr .cl12SwmDashBrd ul {
  padding: 0px 0px;
}
.col8SemNvr .cl12SwmDashBrd ul li.Actmvr {
  color: #4f80ff;
}
.col8SemNvr .cl12SwmDashBrd ul li.Actmvr a {
  color: #4f80ff;
  font-size: 13px;
  text-decoration: none;
}
.col8SemNvr .cl12SwmDashBrd ul li.Actmvr::before {
  top: 2px;
  right: -4px;
  content: ">";
  position: absolute;
  font-size: 10px;
}
.col8SemNvr .cl12SwmDashBrd ul li {
  display: inline-block;
  font-size: 13px;
  color: #666;
  position: relative;
  padding: 0px 10px;
}
.col8SemNvr .cl12SwmDashBrd ul li:first-child {
  padding-left: 0px;
}

.ClsoIconSwn {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  background: #385265;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 15px;
  cursor: pointer;
}
.ClsoIconSwn img {
  width: 25px;
}

.lngoSwn .LnoShnHr {
  display: flex;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  height: 90px;
  align-items: center;
  justify-content: center;
}
.UpldFle {
  display: block;
  position: relative;
}

.UpldFle input {
  display: none;
}

.overflow-hidden {
  overflow: auto;
}

.UpldFle label {
  display: block;
  padding: 8px 5px;
  text-align: center;
  background-color: #456277;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
}
.Dslynone {
  display: none;
}

/* Tinku End */

/* as */
.Cl12Custom {
  padding: 20px;
  width: 100%;
}

.Cl12Custom .col-mdswn-8 {
  padding-left: 60px;
  width: 100%;
  padding-top: 60px;
}
.HdingSwnvr {
  position: relative;
  display: block;
}

.HdingSwnvr h5 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.HdingSwnvr .form-switch .form-check-input:checked {
  background-position: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}

.HdingSwnvr .form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}

.HdingSwnvr .form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.flex.col12WdthSwhnMn.mreSeting {
  width: 100%;
  position: relative;
}

.flex.col12WdthSwhnMn.GftCrdLsitSwn {
  overflow: hidden;
  width: 98%;
}
.col12WdthSwhnMn {
  width: 100%;
  position: relative;
  display: block;
  padding: 20px;
}
.WdtPerMn {
  position: relative;
  display: block;
}

.FntSwnvr {
  font-size: 12px;
  color: green;
  font-weight: 500;
  position: absolute;
  margin-top: -22px;
}
.WdtPerMn a {
  display: inline-block;
  position: relative;
  padding: 8px 20px;
  border: 1px solid #456277;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin-left: 10px;
  color: #456277;
  margin-right: 10px;
}

.WdtPerMn a:last-child {
  background-color: #456277;
  color: #fff;
  margin-right: 0px;
}
.WdtPerMn .upldCsv a {
  display: inline-block;
  padding: 0px;
  background-color: transparent;
  border: none;
}

.WdtPerMn .upldCsv a img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.ServeCtgrySwn input {
  border-radius: 2px;
  padding-right: 30px;
}

.ServeCtgrySwn button {
  padding: 0.5rem 15px;
  border-radius: 3px;
  white-space: nowrap;
}
.TableService {
  position: relative;
}

.TableService tr:nth-child(even) {
  background-color: #f7f7f7;
}
.ArrowSwn {
  position: relative;
  display: inline-block;
}

.ArrowSwn .Dotsarwmn {
  position: relative;
  display: block;
  margin-left: 10px;
  cursor: pointer;
}

.ArrowSwn .Dotsarwmn span {
  display: block;
  width: 4px;
  height: 4px;
  background-color: #456277;
  border-radius: 50%;
  margin-bottom: 3px;
}
.EditSwn {
  position: absolute;
  right: -95px;
  top: -9px;
  background: #456277;
  padding: 5px 0px;
  opacity: 0;
  visibility: hidden;
  border-radius: 3px;
  transition: all 1s ease;
}

.EditSwn.EditShow {
  opacity: 1;
  visibility: visible;
  right: -75px;
}

.EditSwn::before {
  top: 15px;
  left: -4px;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #456277;
  transform: rotate(45deg);
}

.EditSwn a,
.EditSwn button {
  display: block;
  font-size: 12px;
  color: #fff;
  padding: 0px 5px;
  line-height: 1.3;
}
.AddCtgrySwnMnvr {
  position: relative;
  display: block;
  margin-top: 25px;
}
.cl12CtgrySwncl12 {
  width: 75%;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.cl12CtgrySwncl12 .cl4mn {
  width: 35%;
  position: relative;
}

.cl12CtgrySwncl12 .cl8mn {
  width: 80%;
  position: relative;
}

.cl12CtgrySwncl12 {
  margin-bottom: 20px;
}

.ulodFleswn input {
  display: none;
}
.ulodFleswn span {
  display: inline-block;
  background-color: #eee;
}

.ulodFleswn span img {
  display: inline-block;
  width: 13px;
}

.ulodFleswn {
  font-size: 14px;
  padding: 9px 20px;
  background: #eee;
  border-radius: 5px;
  cursor: pointer;
}
.TopSerVicesHder {
  position: relative;
  display: block;
  margin-top: 15px;
}

.TopSerVicesHder ul li {
  display: inline-block;
  position: relative;
}

.TopSerVicesHder ul li a {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  padding: 5px 12px;
  border: 1px solid #eee;
  border-radius: 3px;
}

.TopHdingSwn {
  position: relative;
  display: block;
  padding: 5px 10px;
  font-size: 14px;
  background: #eee;
  margin-bottom: 15px;
}

.TopHdingSwn::before {
  top: 0px;
  left: -16px;
  position: absolute;
  content: "";
  right: 0px;
  width: 103.5%;
  background-color: #eee;
  height: 100%;
  right: -15px;
  z-index: -1;
}

.MreSrtIng {
  position: absolute;
  right: 0px;
  top: -20px;
  text-align: right;
}

.MreSrtIng button {
  font-size: 14px;
}

.MreSrtIng span {
  display: block;
  font-size: 13px;
  color: #666;
  margin-top: 2px;
}

.WdthMreSetting {
  width: 85%;
  position: relative;
}

.HdingSwnvr .form-switch .form-check-input:checked {
  background-position: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}

.HdingSwnvr .form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}

.jodit-workplace {
  height: 150px !important;
}
.TimgSwmvrMnvr {
  padding: 0px;
  border: 1px solid #eee;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}
.editbtn {
  width: 50px;
  margin-top: 10px;
  cursor: pointer;
}

.editbtn img {
  width: 100%;
}

.TableSwhn td {
  opacity: 0.5;
  pointer-events: none;
  position: relative;
}

.TableSwhn.ShownVr td {
  opacity: 1;
  pointer-events: inherit;
}
.Bustop li a.Selected {
  background-color: #fff;
}
.ToppromotionsHder {
  position: relative;
  display: block;
  margin-top: 15px;
}

.ToppromotionsHder ul li {
  display: inline-block;
  position: relative;
}

.ToppromotionsHder ul li a {
  display: inline-block;
  /* font-size: 13px;
  font-weight: 400;
  padding: 5px 12px;
  border-radius: 3px; */
  font-weight: bold;
  background-color: #fafcfe;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 24px;
  border: 1px solid #dcdde1;
}

.ToppromotionsHder ul li a.active {
  background-color: #456277;
  color: white;
}
.TopedittagsHder {
  position: relative;
  display: block;
  margin-top: 15px;
}

.TopedittagsHder ul li {
  display: inline-block;
  position: relative;
}

.TopedittagsHder ul li a {
  display: inline-block;
  font-weight: bold;
  background-color: #fafcfe;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid #dcdde1;
}

.TopedittagsHder ul li a.active {
  background-color: #456277;
  color: white;
}
.DslySwn a {
  display: inline-block;
  padding: 5px 35px;
  border: 1px solid #456277;
  color: #456277;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 10px;
  transition: all 1s ease;
}

.DslySwn .Svebtn {
  display: inline-block;
  padding: 5px 35px;
  border: 1px solid #456277;
  color: #fff;
  background-color: #456277;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 0px;
  transition: all 1s ease;
  cursor: pointer;
}

.DslySwn .Svebtn:hover {
  background-color: #fff;
  color: #456277;
}

.DslySwn a:hover {
  background-color: #456277;
  color: #fff;
}
.cl12CtgrySwncl12 {
  margin-bottom: 20px;
}

.HdingSwnvr {
  position: relative;
  display: block;
}

.HdingSwnvr h5 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.cl12CtgrySwncl12 .cl4mn {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.cl12CtgrySwncl12 input {
  font-size: 14px;
}

.cl12CtgrySwncl12 .cl8mn {
  width: 80%;
  position: relative;
}
.clo12SwnServ {
  position: relative;
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.col12New {
  width: 100%;
  position: relative;
  display: block;
  margin-top: 24px;
}
.SerCtgSwnMnX {
  margin-top: 20px;
}
.TableService {
  position: relative;
}

.TableService tr:nth-child(even) {
  background-color: #f7f7f7;
}
.zIndexMax {
  z-index: 999;
}
.zMax{
  position: relative;
  z-index:20;
}